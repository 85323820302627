import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import BinaryDatabases from './_binary-databases.mdx';
import ConversionUtility from './_conversion-utility.mdx';
import CsvDatabasesIntro from './_csv-databases-intro.mdx';
import DataPrivacyWarning from './_data-privacy-warning.mdx';
import ExampleFiles from './_example-files.mdx';
import FileFormat from './_file-format.mdx';
import APIs from './_apis.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ZipFileContent = makeShortcode("ZipFileContent");
const CsvFileExamples = makeShortcode("CsvFileExamples");
const MmdbFileExamples = makeShortcode("MmdbFileExamples");
const DatabaseSizes = makeShortcode("DatabaseSizes");
const DatabaseChanges = makeShortcode("DatabaseChanges");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Determine the connection type of your visitors based on their IP address. The
database identifies cellular, cable/DSL, and corporate connection speeds.`}</p>
    <p>{`To learn more about the GeoIP2 Connection Type database, including terms and
pricing, please
`}
      <a {...{
        "href": "https://www.maxmind.com/en/geoip2-connection-type-database",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer",
        "parentName": "p"
      }}>{`visit the GeoIP2 Connection Type Database page`}</a>
      {`.`}</p>
    <DataPrivacyWarning mdxType="DataPrivacyWarning" />
    <div {...{
      "id": "toc-binary-database"
    }}><h2 {...{
        "id": "binary-database",
        "parentName": "div"
      }}>{`Binary Database`}</h2>
      <BinaryDatabases mdxType="BinaryDatabases" />
      <table {...{
        "parentName": "div"
      }}>

        <thead {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "thead"
          }}>

            <th {...{
              "parentName": "tr"
            }}>{`Language or Framework`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Documentation`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Example`}</th>

          </tr>

        </thead>


        <tbody {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`.NET (C#)`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-dotnet#connection-type-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Connection Type Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Java`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-java#connection-type",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Connection Type Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Node.js`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-node#connection-type-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Connection Type Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`PHP`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-php#connection-type-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Connection Type Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Python`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#database-usage",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Usage`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-python#connection-type-database",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Connection Type Example`}</a></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Ruby`}</td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#database-reader",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`GitHub - Database Reader`}</a></td>


            <td {...{
              "parentName": "tr"
            }}><a {...{
                "href": "https://github.com/maxmind/GeoIP2-ruby#connection-type-example",
                "target": "_blank",
                "rel": "nofollow noopener noreferrer",
                "parentName": "td"
              }}>{`Connection Type Example`}</a></td>

          </tr>

        </tbody>

      </table></div>
    <div {...{
      "id": "toc-csv-database"
    }}><h2 {...{
        "id": "csv-database",
        "parentName": "div"
      }}>{`CSV Database`}</h2>
      <CsvDatabasesIntro mdxType="CsvDatabasesIntro" />
      <ZipFileContent productName="GeoIP2-Connection-Type" mdxType="ZipFileContent" />
      <FileFormat mdxType="FileFormat" />
      <div {...{
        "id": "toc-blocks-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "blocks-files",
          "parentName": "div"
        }}>{`Blocks Files`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`There are two CSV files for network blocks, one each for IPv4 and IPv6 blocks.
These are named `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-Connection-Type-Blocks-IPv4.csv`}</inlineCode>
          {` and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`GeoIP2-Connection-Type-Blocks-IPv6.csv`}</inlineCode>
          {` respectively.`}</p>
        <table>
          <tbody>
            <tr>
              <th>
                <p>{`Name`}</p>
              </th>
              <th>
                <p>{`Type`}</p>
              </th>
              <th>
                <p>{`Description`}</p>
              </th>
            </tr>
            <tr>
              <td>
                <p>{`network`}</p>
              </td>
              <td>
                <p>{`IP network as a string`}</p>
              </td>
              <td>
                <p>{`This is the IPv4 or IPv6 network in CIDR format such as
“2.21.92.0/29” or “2001:4b0::/80”. We offer a utility to convert
this column to start/end IPs or start/end integers. See `}
                  <a href="#conversion-utility">{`the conversion utility section`}</a>
                  {` for
details.`}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{`connection_type`}</p>
              </td>
              <td>
                <p>{`string`}</p>
              </td>
              <td>
                <p>{`One of the following values: `}
                  <inlineCode {...{
                    "parentName": "p"
                  }}>{`Cable/DSL`}</inlineCode>
                  {`, `}
                  <inlineCode {...{
                    "parentName": "p"
                  }}>{`Cellular`}</inlineCode>
                  {`, `}
                  <inlineCode {...{
                    "parentName": "p"
                  }}>{`Corporate`}</inlineCode>
                  {`, or
`}
                  <inlineCode {...{
                    "parentName": "p"
                  }}>{`Satellite`}</inlineCode>
                  {`. Additional values may be added in the future.`}</p>
                <p>{`*`}
                  {` `}
                  <a href="https://support.maxmind.com/hc/en-us/articles/4408200231067-IP-Network-Data#h_01FN988THBX8RVERNTZ12BY2EC" target="_blank" rel="nofollow noopener noreferrer">{`Learn more about connection type data on our Knowledge Base.`}</a></p>
              </td>
            </tr>
          </tbody>
        </table></div>
      <div {...{
        "id": "toc-conversion-utility",
        "parentName": "div"
      }}><h3 {...{
          "id": "conversion-utility",
          "parentName": "div"
        }}>{`Conversion Utility`}</h3>
        <ConversionUtility mdxType="ConversionUtility" /></div></div>
    <div {...{
      "id": "toc-example-files"
    }}><h2 {...{
        "id": "example-files",
        "parentName": "div"
      }}>{`Example Files`}</h2>
      <ExampleFiles mdxType="ExampleFiles" />
      <div {...{
        "id": "toc-csv-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "csv-example-files",
          "parentName": "div"
        }}>{`CSV Example Files`}</h3>
        <CsvFileExamples files={[{
          filename: 'GeoIP2-Connection-Type-CSV_Example.zip',
          link: '/static/GeoIP2-Connection-Type-CSV_Example.zip'
        }]} mdxType="CsvFileExamples" /></div>
      <div {...{
        "id": "toc-mmdb-example-files",
        "parentName": "div"
      }}><h3 {...{
          "id": "mmdb-example-files",
          "parentName": "div"
        }}>{`MMDB Example Files`}</h3>
        <MmdbFileExamples files={[{
          filename: 'GeoIP2-Connection-Type-Test.mmdb',
          link: 'https://github.com/maxmind/MaxMind-DB/blob/main/test-data/GeoIP2-Connection-Type-Test.mmdb'
        }]} mdxType="MmdbFileExamples" /></div></div>
    <div {...{
      "id": "toc-database-sizes"
    }}><h2 {...{
        "id": "database-sizes",
        "parentName": "div"
      }}>{`Database Sizes`}</h2>
      <DatabaseSizes dateRange="March to June 2024" databaseChanges={[{
        databaseName: 'GeoIP Connection Type',
        csvSizeRange: '30.56 MB - 47.44 MB',
        mmdbSizeRange: '9.02 MB - 12.7 MB',
        ipv4Range: '933,000 - 1,550,000',
        ipv6Range: '197,000 - 208,000'
      }]} mdxType="DatabaseSizes" /></div>
    <div {...{
      "id": "toc-database-changes"
    }}><h2 {...{
        "id": "database-changes",
        "parentName": "div"
      }}>{`Database Changes`}</h2>
      <DatabaseChanges product="Connection Type" mdxType="DatabaseChanges" /></div>
    <div {...{
      "id": "toc-apis-and-third-party-integrations"
    }}><h2 {...{
        "id": "apis-and-third-party-integrations",
        "parentName": "div"
      }}>{`APIs and Third-Party Integrations`}</h2>
      <APIs mdxType="APIs" /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      